/**
 * Get permissions from route
 * @param {import('vue-router').Route} route Vue Router route object
 * @returns {{scope: string, action: string}|true} Route permission
 */
export default function(route) {
  // Reverse so we have the current route as first array item.
  const matched = route.matched.slice().reverse()

  // Loop "up" through the route hierarchy until we find the first permission.
  for (const item of matched) {
    if (item.meta.permission) {
      return item.meta.permission(route)
    }
  }

  // If no permission has been found, assume the route is free for all.
  return true
}

<script setup>
import { computed } from 'vue'

// Globals
import enums from '../enums.js'
// Components
import CButton from './CButton.vue'
import CIcon from './CIcon.vue'

const props = defineProps({
  type: {
    type: Symbol,
    default: undefined,
    validator: value => Object.values(enums.notification).includes(value)
  },
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    default: undefined
  },
  timeout: {
    type: Number,
    default: 0,
    validator: value => value >= 0
  },
  buttons: {
    type: Array,
    default: null
  }
})

const emit = defineEmits(['close'])

const icon = computed(() => {
  return {
    [enums.notification.SUCCESS]: 'success',
    [enums.notification.ERROR]: 'error'
  }[props.type] ?? 'bell'
})

function onClose() {
  emit('close')
}

function onAction(action) {
  action()
  onClose()
}

if (props.timeout) {
  setTimeout(onClose, props.timeout)
}
</script>

<template>
  <li class="notification pa-5 text-gray-100 bg-gray-900 br-2 bs-large">
    <CButton
      aria-label="Schließen"
      icon="close"
      color="ghost-dark"
      size="small"
      class="notification__close"
      @click="onClose"
    />

    <CIcon
      :type="icon"
      size="1.5"
      class="notification__icon"
    />

    <div class="ml-5">
      <h3 class="notification__title text-bold">
        {{ title }}
      </h3>

      <p
        v-if="content"
        class="mt-5"
        v-html="content"
      />

      <CButton
        v-for="button in buttons"
        :key="button.text"
        color="medium"
        size="small"
        class="mt-5"
        @click="onAction(button.action)"
      >
        {{ button.text }}
      </CButton>
    </div>
  </li>
</template>

<style lang="scss">
.notification {
  position: relative;
  display: flex;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.notification__close {
  position: absolute;
  top: 0.75em;
  right: 0.5em;
}

.notification__icon {
  // Align icon with title.
  margin: -0.04em 0;
}

.notification__title {
  padding-right: 1.75em;
}
</style>

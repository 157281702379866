import { COLLECTION_LIST_ITEM_COUNT } from '../../../settings.js'
import connector from '../../connector.js'

/**
 * Get collections list
 * @returns {import('../../../types').Collection[]}
 */
export async function getList() {
  return connector.get('core/collections').json()
}

export async function getListItems(collectionId, { search, offset, limit = COLLECTION_LIST_ITEM_COUNT }, options) {
  const searchParams = { collection: collectionId, limit, search, offset }
  return connector.get('core/collection-items', { searchParams, ...options, collection: collectionId }).json()
}

/**
 * Get collection
 * @param {number} collectionId Collection ID
 * @returns {import('../../../types').Collection}
 */
export async function getInstance(collectionId) {
  return connector.get(`core/collections/${collectionId}`).json()
}

/**
 *
 * @param {number} collectionId Collection ID to return items for
 */
export async function getCollectionItems(collectionId, { search, limit = 200 }) {
  return connector.get('core/collection-items', { searchParams: { collection: collectionId, search, limit } }).json()
}

/**
 *
 * @param {number} collectionId Collection ID to return items for
 * @param {number} itemId ID of the collection item
 */
export async function getCollectionItemInstance({ collectionId, collectionItemId }) {
  return connector.get(`core/collection-items/${collectionItemId}`, { searchParams: { collection: collectionId } }).json()
}

/**
 * Creates a new collection item inside the collection specified by collectionId
 * @param {number} collectionId Collection ID to return items for
 */
export async function createItem({ collectionId, fields }) {
  return connector.post('core/collection-items', fields, { searchParams: { collection: collectionId } }).json()
}

/**
 * Updates the given collection item
 * @param {number} collectionId Collection ID to return items for
 * @param {number} collectionItemId Collection Item ID to modify
 */
export async function updateItem({ collectionId, collectionItemId, fields }) {
  return connector.patch(`core/collection-items/${collectionItemId}`, fields, { searchParams: { collection: collectionId } }).json()
}

/**
 * Removes the given collection item
 * @param {number} collectionId Collection ID to return items for
 * @param {number} collectionItemId Collection Item ID to modify
 */
export async function removeItem({ collectionId, collectionItemId }) {
  return connector.delete(`core/collection-items/${collectionItemId}`, { searchParams: { collection: collectionId } }).json()
}

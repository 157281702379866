// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'
import store from '../../store/index.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'settings',
    component: () => import('../../views/VSettingsIntro.vue')
  },
  {
    path: 'konten',
    name: 'settings-user',
    component: () => import('../../views/VSettingsUser.vue'),
    meta: {
      permission: () => userCan(enums.permissions.AUTH, 'view_user'),
      title: 'Alle Konten'
    }
  },
  {
    path: 'konten/neu',
    name: 'settings-user-create',
    component: () => import('../../views/VSettingsUserForm.vue'),
    meta: {
      permission: () => userCan(enums.permissions.AUTH, 'add_user'),
      title: 'Neues Konto'
    }
  },
  {
    path: 'konten/:id',
    name: 'settings-user-edit',
    component: () => import('../../views/VSettingsUserForm.vue'),
    props: route => ({
      id: parseInt(route.params.id)
    }),
    meta: {
      permission: (route) => parseInt(route.params.id) === store.state.user.current.id || userCan(enums.permissions.AUTH, 'view_user'),
      title: 'Konto bearbeiten'
    }
  },
  {
    path: 'system',
    name: 'settings-system',
    component: () => import('../../views/VSettingsSystem.vue'),
    meta: {
      permission: () => userCan(enums.permissions.GENERAL, 'view_brontendconfiguration'),
      title: 'System'
    }
  },
  {
    path: 'import',
    component: () => import('../../views/VSettingsImport.vue'),
    meta: {
      permission: () => userCan(enums.permissions.GENERAL, 'view_import'),
      title: 'Import',
      sidebar: {
        content: import('../../components/CSettingsSidebarImport.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'settings-import',
        component: () => import('../../views/VSettingsImportIntro.vue')
      },
      {
        path: 'upload',
        name: 'settings-import-upload',
        component: () => import('../../views/VSettingsImportUpload.vue'),
        meta: {
          title: 'Datei-Upload'
        }
      },
      {
        path: 'post',
        name: 'settings-import-post',
        component: () => import('../../views/VSettingsImportPost.vue'),
        meta: {
          title: 'HTTP POST'
        }
      },
      {
        path: 'log',
        name: 'settings-import-log',
        component: () => import('../../views/VSettingsImportLog.vue'),
        meta: {
          title: 'Import-Log'
        }
      }
    ]
  },
  {
    path: ':pathMatch(.*)',
    redirect: { name: 'settings' }
  }
]

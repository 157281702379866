/**
 * @typedef {Object} Category
 * @property {string} name Category name
 * @property {number} identifier ID
 */

/**
 * Flatten analysis categories for sequential rendering
 * @param {Category[]} categories Catagory list
 * @param {boolean} [includeWithoutLayout=true] Determines whether 'layoutless' categories should be returned
 * @returns {{name:string, id:number, level:number}[]} Flattened category list
 */
export default function(categories, includeWithoutLayout = true) {
  const flattened = []

  /**
   * Extract category information and append to sequential array
   * @param {Category} category Current category
   * @param {number} level Current level
   */
  function flatten(category, level) {
    if (includeWithoutLayout || category.has_layout) {
      flattened.push({
        name: category.name,
        id: category.identifier,
        has_layout: category.has_layout,
        level
      })
    }

    category.children.forEach(child => flatten(child, level + 1))
  }
  categories.forEach(category => flatten(category, 0))

  return flattened
}

import * as category from './category.js'
import * as certificate from './certificate.js'
import * as certifiedTestable from './certifiedTestable.js'
import * as config from './config.js'
import * as material from './material.js'
import * as parameter from './parameter.js'
import * as tag from './tag.js'
import * as testable from './testable.js'

export default {
  certificate,
  config,
  material,
  parameter,
  tag,
  category,
  testable,
  certifiedTestable,
}
